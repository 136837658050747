import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useReportList() {
  const users = ref([])
  const usersOrigin = ref([])
  const currentUser = ref({})
  const isPopupUserModal = ref(false)
  const userModalMode = ref('create')
  const userModalData = ref({})
  const showImage160 = ref([])

  const searchId = ref('')
  const searchBranch = ref('')
  const searchQuery = ref('')

  const branchs = computed(() => store.state.app.branches)
  // Table Handlers
  const tableColumnsUsers = [
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'text-primary',
      thClass: ' vertical-middle',
      sortBy: 'id',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Ảnh',
      key: 'image',
      thClass: 'vertical-middle',
    },
    {
      label: 'Họ tên',
      key: 'fullname',
      tdClass: 'py-75 text-primary',
      thClass: ' vertical-middle',
      sortBy: 'fullname',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Chi nhánh',
      key: 'branch.name',
      sortBy: 'branch.name',
      thClass: 'vertical-middle',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Nguồn tìm về',
      key: 'sum_source_townhouse',
      tdClass: 'text-center',
      thClass: ' vertical-middle',
      sortBy: 'sum_source_townhouse',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Tổng nhà phố',
      key: 'sum_user_townhouse',
      tdClass: 'text-center',
      thClass: ' vertical-middle',
      sortBy: 'sum_user_townhouse',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Nhập NP <100tr',
      key: 'total_sum_user_office_price_low',
      tdClass: 'text-center',
      thClass: ' vertical-middle',
      sortBy: 'total_sum_user_office_price_low',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Nhập NP >100tr',
      key: 'total_sum_user_office_price_high',
      tdClass: 'text-center',
      thClass: ' vertical-middle',
      sortBy: 'total_sum_user_office_price_high',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Tổng nhập VP',
      key: 'sum_user_office',
      tdClass: 'text-center',
      thClass: ' vertical-middle',
      sortBy: 'sum_user_office',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Khách nhà phố',
      key: 'sum_client_townhouse',
      tdClass: 'text-primary text-center',
      thClass: ' vertical-middle',
      sortBy: 'sum_client_townhouse',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Khách văn phòng',
      key: 'sum_client_office',
      tdClass: 'text-primary text-center',
      thClass: ' vertical-middle',
      sortBy: 'sum_client_office',
      sortDesc: false,
      sortable: true,
    },
    {
      label: 'Bán',
      key: 'total_sum_user_townhouse_sell',
      tdClass: 'text-primary text-center',
      thClass: ' vertical-middle',
      sortBy: 'total_sum_user_townhouse_sell',
      sortDesc: false,
      sortable: true,
    },
  ]
  const currentPage = ref(1)
  const totalContracts = ref(0)
  const resultTitle = ref('')

  const m = new Date().getMonth() + 1
  const y = new Date().getFullYear()
  const monthFilter = ref(null)
  const yearFilter = ref(y)

  const getDateRange = () => {
    if (!yearFilter.value) {
      return {
        from: '',
        to: '',
      }
    }

    return {
      from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
        : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
      to: !monthFilter.value ? new Date(yearFilter.value, 12, 0) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
    }
  }

  const getQuery = (filter = {}) => {
    const {
      from,
      to,
    } = getDateRange()

    return http.buildQuery({
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
    }) || ''
  }

  const fetchReport = (filter = {}) => {
    const queryString = getQuery(filter)
    return http.handle(store.dispatch('report/fetchReport', queryString), res => {
      usersOrigin.value = res.data || []
    })
  }

  const fetchUser = id => http.handle(store.dispatch('user/fetchUser', { id }), res => {
    userModalData.value = res.data
    isPopupUserModal.value = true
  })

  const updateUser = data => http.handle(store.dispatch('user/updateUser', data), res => {
    userModalData.value = res.data
    Object.assign(users.value.find(u => u.id === res.data.id), res.data)
  })

  const createUser = data => http.handle(store.dispatch('user/createUser', data), res => {
    isPopupUserModal.value = false
    users.value.unshift(res.data)
  })

  const deleteUser = user => http.handle(store.dispatch('user/deleteUser', user), () => {
    const idx = users.value.findIndex(u => u.id === user.id)
    users.value.splice(idx, 1)
  })

  const filterUsers = () => {
    if (!searchId.value && !searchQuery.value && !searchBranch.value) {
      users.value = usersOrigin.value
      return
    }
    users.value = usersOrigin.value.filter(u => {
      if (searchId.value) {
        const qid = searchId.value.toString().toLowerCase()
        if (qid && (u.id.toString().toLowerCase() === qid)) return true
      }
      if (searchBranch.value) {
        const qbranch = searchBranch.value.toString().toLowerCase()
        if (qbranch && (u.branch?.id?.toString()?.toLowerCase() === qbranch)) return true
      }
      if (searchQuery.value) {
        const q = searchQuery.value?.toLowerCase()
        return q && (u.fullname?.toLowerCase().includes(q) || u.phone?.toLowerCase().includes(q) || u.email?.toLowerCase().includes(q))
      }
      return false
    })
  }

  const searchKey = () => {
    filterUsers()
  }

  watch([yearFilter, monthFilter], () => {
    fetchReport()
  })

  watch([usersOrigin, searchBranch], () => {
    filterUsers()
  })

  return {
    currentUser,
    tableColumnsUsers,
    currentPage,
    totalContracts,
    resultTitle,
    showImage160,
    users,
    branchs,
    usersOrigin,
    searchBranch,
    searchId,
    searchQuery,
    isPopupUserModal,
    userModalMode,
    userModalData,
    yearFilter,
    monthFilter,
    searchKey,
    fetchReport,
    fetchUser,
    updateUser,
    createUser,
    deleteUser,
  }
}
