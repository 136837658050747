<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body class="pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            md="3"
            class="mb-md-0 mb-50 pr-1 pr-sm-0"
          >
            <label>Mã</label>
            <b-form-input
              id="icons-search"
              v-model="searchId"
              :autofocus="true"
              size="sm"
              placeholder="Mã nhân viên"
              @keydown.enter.prevent="searchKey"
            />
          </b-col>
          <b-col
            cols="12"
            sm="4"
            md="3"
            class="mb-md-0 mb-50 pr-1 pr-sm-0"
          >
            <label>Chi nhánh</label>
            <v-select
              v-model="searchBranch"
              :options="branchs"
              label="address"
              class="select-size-sm"
              :reduce="option => option.id"
            />
          </b-col>
          <b-col
            cols="12"
            sm="4"
            md="3"
            class="mb-md-0 mb-50"
          >
            <label>Tìm kiếm nội dung</label>
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="icons-search"
                  v-model="searchQuery"
                  :autofocus="true"
                  size="sm"
                  placeholder="Tên, email, số đt"
                  @keydown.enter.prevent="searchKey"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer"
                    @click="searchKey"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            md="3"
            class="mb-md-0 mb-50"
          >
            <label>Tháng / Năm</label>
            <div class="d-flex align-items-center">
              <v-select
                v-model="monthFilter"
                class="select-size-sm mr-25"
                label="name"
                :options="MONTH_OPTIONS"
                :searchable="false"
              />
              <v-select
                v-model="yearFilter"
                class="select-size-sm"
                label="name"
                :options="YEAR_OPTIONS"
                :searchable="false"
              />
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          DANH SÁCH NHÂN VIÊN ({{ users.length }})
        </b-badge>
      </div>
      <b-table
        ref="table-users"
        class="position-relative px-2"
        responsive
        primary-key="id"
        sticky-header
        show-empty
        :sort-by="sortBy"
        :sort-desc.sync="sortDesc"
        hover
        small
        sort-icon-left
        empty-text="Không có kết quả nào"
        :items="users"
        :fields="tableColumnsUsers"
        @row-clicked="handleRowClick"
      >
        <!-- Column: ảnh đại diện -->
        <template #cell(image)="data">
          <b-media-aside class="mr-75">
            <b-avatar
              size="48"
              rounded
              :src="data.item.avatar ? getUrlFile(data.item.avatar, '', '240') : ''"
              :text="data.item.avatar ? '' : 'CL'"
            />
          </b-media-aside>
        </template>
        <template #head(sum_user_townhouse)="data">
          Tổng <br> nhà phố
        </template>
        <template #head(total_sum_user_office_price_low)="data">
          Thuê <br> &le; 90 triệu
        </template>
        <template #head(total_sum_user_office_price_high)="data">
          Thuê <br> > 90 triệu
        </template>
        <template #head(sum_client_townhouse)="data">
          Khách <br>nhà phố
        </template>
        <template #head(sum_client_office)="data">
          Khách <br>văn phòng
        </template>
        <!-- Column: Nguồn tìm về -->
        <template #head(sum_source_townhouse)="data">
          Nguồn nhà <br>phố tìm về
        </template>
        <template #cell(sum_source_townhouse)="data">
          {{ data.item.sum_source_townhouse }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCard,
  BCardBody,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BMediaAside,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import reportStoreModule from '@/views/report/reportStoreModule'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import useReportList from './useReportList'

export default {
  components: {
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    vSelect,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BMediaAside,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      ability,
      subject,
      sortBy: 'id',
      sortDesc: false,
    }
  },
  created() {
    this.fetchReport()
    this.$store.dispatch('app/fetchBranches')
  },
  methods: {
    handleRowClick(row) {
      // this.popupEdit(row)
      this.$router.push({ name: 'user-detail', params: { id: row.id } })
    },
    openModalConfirmDeleteUser(u) {
      this.currentUser = u
      this.$refs['modal-confirm-delete-user'].show()
    },
    submitForm(modalMode, modalData) {
      if (modalMode === 'create') {
        this.$call(this.createUser({ ...modalData, id: '' }), true)
      }
      if (modalMode === 'edit') {
        this.$call(this.updateUser(modalData), true)
      }
    },
    popupAddUser() {
      this.userModalMode = 'create'
      this.isPopupUserModal = true
      this.userModalData = null
    },
    popupEdit(user) {
      this.userModalMode = 'edit'
      this.$call(this.fetchUser(user.id))
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    return {
      ...useReportList(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
